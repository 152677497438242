// CSS and Bootstrap Call
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import ReactGA from "react-ga";
import React from "react";
import ReactDOM from "react-dom";
import "./styles/Index.css";

import Router from "./Router";

ReactGA.initialize("G-MX1QR37CPV");
ReactGA.pageview(window.location.pathname + window.location.search);
ReactDOM.render(<Router />, document.getElementById("root"));

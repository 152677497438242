import React from "react";
import { Helmet } from "react-helmet";

/* 
Professor (Dr) Gaurav Sood is a brand evangelist, researcher, educator, speaker, columnist and author, with more than two-decade practice creating strong brands. He has been an integral part of developing and managing marketing and integrated marketing communication campaigns for global brands in India, Germany and the United States.
*/

export default function MetaSEO({ title }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="author" content="Dr. Gaurav Sood" />
      <link rel="canonical" href={window.location.href} />
      <meta name="canonical" content={window.location.href} />
      <meta name="medium" content="mult" />
      <meta itemprop="name" content={title} />
      <meta itemprop="url" content={window.location.href} />
    </Helmet>
  );
}

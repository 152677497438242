import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

// Screens
import App from "./screens/App";
import Contactus from "./screens/Contactus";
import Books from "./screens/Books";
import Articles from "./screens/Articles";
import Cases from "./screens/Cases";
import Webinars from "./screens/Webinars";
import Privacy from "./screens/Privacy";
import Legal from "./screens/Legal";
import Media from "./screens/Media";
import NotFound from "./screens/NotFound";
import Hype from "./screens/Hype";
import StudyAbroad from "./screens/StudyAbroad";

// Components
import Header from "./components/Header";
import Footer from "./components/Footer";
import ShowSingleArticle from "./components/ShowSingleArticle";
import ShowSingleCase from "./components/ShowSingleCase";

const Router = () => {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Switch>
          <Route exact path="/" component={App} />
          <Route exact path="/hype" component={Hype} />
          <Route exact path="/contact-us" component={Contactus} />
          <Route exact path="/books" component={Books} />
          <Route exact path="/articles" component={Articles} />
          <Route exact path="/article/:slug" component={ShowSingleArticle} />
          <Route exact path="/cases" component={Cases} />
          <Route exact path="/case/:slug" component={ShowSingleCase} />
          <Route exact path="/study-abroad" component={StudyAbroad} />
          <Route exact path="/webinars" component={Webinars} />
          <Route exact path="/privacy-policy" component={Privacy} />
          <Route exact path="/legal" component={Legal} />
          <Route exact path="/media" component={Media} />
          <Route exact path="/page-not-found" component={NotFound} />
          <Route path="*">
            <Redirect to="/page-not-found" />
          </Route>
        </Switch>
        <Footer />
      </BrowserRouter>
    </>
  );
};

export default Router;

export const books = [
  {
    id: 5,
    bookTitle: "FAKE NEWS",
    keyPoints: [
      "How fake news is used as guerrilla communication?",
      "How fake news is a form of culture jamming?",
      "How fake news affect what we buy and consume",
      "Why do people fall for fake news?",
      "How memes stir up fake news?",
      "What is the role of media in spreading fake news?",
      "How fake news is amplified?",
      "What is the truth behind fake news?",
      "How to Protect and Defend yourself from Fake News",
    ],
    bookCover: `https://res.cloudinary.com/dqmyvpfu0/image/upload/v1699110509/books/FakeNews_cfez6y.webp`,
    mainContent: `The news is a public good and needs to be handled with care and integrity. Even though lies and misinformation campaigns have been around for years—maybe since the dawn of journalism—the rate at which fake news is being spread these days is both alarming and preposterous. Almost every institution—public or private—uses fake news to further its own agenda. 
    Governments and corporate houses spread fake news either through their own agencies or by influencing the popular media. In the business sector, fake news manifests itself in the form of exaggerated company returns and false data. This book analyses the impact of fake news both on products and personalities. 
    Foregrounded in rigorous research, it examines how fake news is used by companies, political parties, and leaders to create, amplify, and even tarnish a brand's image and equity. It emphasizes how the customers' perception of a brand impacts and influences its reputation, and acts as a decisive force in them gaining or losing competitive advantages. Elucidating how brands can interact both directly and indirectly with fake news, it brings to the readers' notice how sometimes brands are the victims of fake news and other times, the purveyors.`,
  },
  {
    id: 4,
    bookTitle: "TROLL PROOF BRANDING IN THE AGE OF DOPPELGANGER",
    keyPoints: [
      "How Culture Jamming, Fake News and Trolls create brand doppelganger",
      "Strong brands are vulnerable to its brand doppelganger",
      "How to manage brand doppelganger",
    ],
    bookCover: `https://res.cloudinary.com/dqmyvpfu0/image/upload/v1643825930/books/C_m3lgtm.webp`,
    mainContent: `Fake News, Hacktivism, Internet Trolls and Culture Jamming are rampant pop culture phenomena in the age of consumerism.

    Clever strategies such as emotional branding and competitive pricing can only help your brand go as far as one little slip from grace. In the blink of an eye, your brand's image can be tarnished by its wicked doppelganger. Tweets by angry fans along with memes about your brand can become your worst nightmare. 
    
    Troll Proof Branding in The Age of Doppelgangers captures the evolution of the brand alter-ego also known as doppelganger created by consumers, anti-brand activists, competitors, opinion leaders, media and the public. Arm yourself with strategies to promote brand positivity to fight trolls and hacktivists. This book aids you in developing counter strategies to lessen the impact of your brand doppelgangers.
    `,
  },
  {
    id: 3,
    bookTitle: "GLOBAL BUSINESS STRATEGIES FOR SUSTAIABILITY 2021",
    keyPoints: [
      "GLOBUSS 2021 International Research Conference",
      "High quality research papers in the field of Marketing, HR, Finance",
      "Eminent speakers from Industry & Academia",
    ],
    bookCover: `https://res.cloudinary.com/dqmyvpfu0/image/upload/v1643825930/books/A_uocanh.webp`,
    mainContent: `Businesses have spent much of the past 15 months scrambling to adapt to extraordinary circumstances. While the fight against the COVID-19 pandemic is not yet won, with a vaccine in sight, there is at least a faint light at the end of the tunnel—along with the hope that another train isn't heading our way. 2021-22 will be the year of transition. Barring any unexpected catastrophes, individuals, businesses, and society can start to look forward to shaping their futures rather than just grinding through the present. The next normal is going to be different. It will not mean going back to the conditions that prevailed in 2019. Indeed, just as the terms “pre-war” and “post-war” are commonly used to describe the 20th century, generations to come will likely discuss the pre-COVID-19 and post-COVID-19 eras. In this GLOBUSS-21 International Research Conference, we will identify some of the trends that will shape the next normal. How they will affect the direction of the global economy, how business will adjust, and how society could be changed forever as a result of the COVID-19 crisis. It is expected to have an enriching exchange of views from the experts towards Managing Business Strategies in the times of Crisis in all functional areas of management for Business Excellence.`,
  },
  {
    id: 2,
    bookTitle: "GLOBAL BUSINESS STRATEGIES FOR SUSTAIABILITY 2019",
    keyPoints: [
      "GLOBUSS 2019 International Research Conference",
      "High quality research papers in the field of Marketing, HR, Finance",
      "Eminent speakers from Industry & Academia",
    ],
    bookCover: `https://res.cloudinary.com/dqmyvpfu0/image/upload/v1643825930/books/B_q4oqy8.webp`,
    mainContent: `In today's world, when we can no longer sit and relax in the comfort of our own courtyard, as the narrow barrier of trade or culture, is breaking down for bigger benefits, it is time to ponder and analyze 'Business going digital' and their various implications on operational efficiency and our society and environment. Today's megatrends are reshaping the world, creating abundant opportunities and essential threats. Technology, globalization and demographics are the root of the megatrends shaping the world, creating unprecedented disruption across all sectors.  In this changing scenario, it will be interesting to study and research the process of seeing the human impact that this approach can make: How customers' experiences can become richer and more personalized through data-led insights; how trust can be built by using digital technologies to bring operational efficiency to industry and how talent can be liberated and opportunities created with the application of Artificial Intelligence. This is how we are answering tomorrow's questions today. 
    Researchers all over the globe are requested to accept our invitation and enrich basket of human knowledge with their findings-Is digital transformation? In what way global business strategies can contribute towards this goal? 
    `,
  },
  {
    id: 1,
    bookTitle:
      "Impact Of Doppelgänger Brand Image On Indian Premier League (IPL)",
    keyPoints: [
      "What is Brand Doppelganger Imagery?",
      "Indian Premier League (IPL) - Impact of negative brand associations",
      "Brand Concept Mapping methodology and its implementation",
    ],
    bookCover: `https://res.cloudinary.com/dqmyvpfu0/image/upload/v1643825931/books/IPL_book_izexak.webp`,
    mainContent: `Brands are not only influencing our lives but actually to a large extent controlling them. To understand customers' perceptions of brand(s) is a paramount aspect of brand management and Gaurav Sood has critically examined these perceptions. His prime vehicle has been scrutinizing the imagery of the brand, Indian Premiere League (IPL), in his book. The brand custodians strive to build a positive perception in the minds of the consumers - they succeed in some and fail in others. 
    Doppelgänger brand image as captured and analyzed in this book comprises of negative perception about the brand in question and in this case, on IPL. Gaurav has picked up the right brand for his study as IPL is mired in controversies giving rise to creation of its doppelgänger. This book is an in-depth biopsy into IPL's Doppelgänger Brand Image is worth studying.
    `,
  },
];

import React from "react";
import MetaSEO from "../components/MetaSEO";
import { MEDIA } from "../model/media";

export default function Media() {
  return (
    <>
      <MetaSEO title={`Books Media Coverage - Dr. Gaurav Sood`} />

      <section style={{ padding: "5rem 0" }}>
        <div className="container">
          <h1 className="heading text-white text-center">
            Books Media Coverage
          </h1>
        </div>

        <div className="container-fluid d-relative">
          <div className="bar-block-design">
            <div className="article-grid-container">
              {MEDIA.map((content, i) => {
                return (
                  <div className="card article-card" key={i}>
                    {content && content?.image && (
                      <img
                        src={content?.image}
                        width="100%"
                        height="100%"
                        className="card-img-top"
                        alt={content.title}
                        loading="lazy"
                        style={{
                          objectFit: "cover",
                        }}
                      />
                    )}
                    {content && content.url && (
                      <div className="card-body article-card-text-block text-center">
                        <a
                          className="btn dark-outline-btn"
                          target="_blank"
                          rel="noreferrer"
                          href={`${content.url}`}
                        >
                          Checkout now
                        </a>
                      </div>
                    )}

                    {content && content?.video && (
                      <div className="text-center">
                        <video width="82%" height="100%" autoPlay>
                          <source src={content?.video} type="video/mp4" />
                          <source src={content?.video} type="video/ogg" />
                        </video>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import { useState } from "react";

import { db } from "../firebase";
import { collection, addDoc } from "firebase/firestore";

import MetaSEO from "../components/MetaSEO";

export default function Contactus() {
  const [getContactFormData, setContactFormData] = useState({
    fullname: "",
    email: "",
    companyName: "",
    telephone: "",
    topic: "",
    message: "",
  });

  const handleChange = async (event) => {
    setContactFormData({
      ...getContactFormData,
      [event.target.name]: event.target.value,
    });

    console.log(getContactFormData);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (getContactFormData !== null) {
      await addDoc(collection(db, "contactForm"), getContactFormData)
        .then((res) => {
          event.target.reset();
          alert("Thank you for contacting");
        })
        .catch((err) => console.log(err));
    }

    setContactFormData({
      fullname: "",
      email: "",
      companyName: "",
      telephone: "",
      topic: "",
      message: "",
    });
    console.log(getContactFormData);
  };

  return (
    <>
      <MetaSEO title={`Contact - Dr. Gaurav Sood`} />

      <figure className="Fold">
        <img
          src={`https://res.cloudinary.com/dqmyvpfu0/image/upload/v1643825930/banners/contact_banner_vbyfwt.webp`}
          alt="contact banner"
          width="100%"
          height="100%"
          loading="lazy"
        />
      </figure>

      <section className="container w-75">
        <h2 className="heading text-center text-white my-5">
          WE'D LOVE TO HEAR FROM YOU.
        </h2>
        <div className="row" style={{ justifyContent: "space-around" }}>
          <div className="col-lg-6">
            {/* <div className="mb-4">
              <h4 className="text-main-color">Address</h4>
              <p className="article-text text-white">
                ABC street, Delhi, India
              </p>
            </div> */}

            <div className="mb-4">
              <h4 className="text-main-color">Telephone</h4>
              <a
                className="article-text text-white text-decoration-none"
                href="tel:+919811355662"
              >
                +91 98113 55662
              </a>
            </div>

            <div className="mb-4">
              <h4 className="text-main-color">Email</h4>
              <a
                className="article-text text-white text-decoration-none"
                href="mailto:gauravsood@hypebrand.in"
              >
                gauravsood@hypebrand.in
              </a>
            </div>
          </div>

          <div className="col-lg-6">
            <p className="text-white" style={{ textAlign: "justify" }}>
              Thank you for your interest in Gaurav Sood. Please fill out the
              form below or send us an email and we'll get back to you within 4
              - 6 hours.
            </p>
            <form method="POST" onSubmit={handleSubmit}>
              <div className="mb-4">
                <input
                  type="text"
                  className="form-control"
                  name="fullname"
                  onChange={handleChange}
                  placeholder="Full Name"
                  required={true}
                />
              </div>
              <div className="mb-4 ">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  onChange={handleChange}
                  placeholder="Email Address"
                  required={true}
                />
              </div>
              {/* <div className="mb-4 ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Verify Email Address"
                />
              </div> */}
              <div className="mb-4 ">
                <input
                  type="text"
                  className="form-control"
                  name="companyName"
                  onChange={handleChange}
                  placeholder="Company Name"
                  required={true}
                />
              </div>
              <div className="mb-4 ">
                <input
                  type="tel"
                  className="form-control"
                  name="telephone"
                  onChange={handleChange}
                  placeholder="Telephone"
                  required={true}
                />
              </div>
              <div className="mb-4">
                <select
                  className="form-control"
                  name="topic"
                  required={true}
                  onChange={handleChange}
                >
                  <option value="1">Topic</option>
                  <option value="Consulting">Consulting</option>
                  <option value="Speaking">Speaking</option>
                  <option value="Press Inquiry/Interview">
                    Press Inquiry/Interview
                  </option>
                  <option value="General">General</option>
                </select>
              </div>
              <div className="mb-4">
                <textarea
                  type="list"
                  className="form-control"
                  rows={4}
                  name="message"
                  onChange={handleChange}
                  required={true}
                  placeholder="Message"
                ></textarea>
              </div>

              <div className="mt-3">
                <input
                  type="submit"
                  value="Submit"
                  name="submit"
                  className="btn yellow-outline-btn form-control m-0"
                />
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

import { webinars } from "../model/webinars";
import MetaSEO from "../components/MetaSEO";

export default function Webinars() {
  return (
    <>
      <MetaSEO title={`Webinars - Dr. Gaurav Sood`} />

      <section>
        <figure className="Fold">
          <img
            src={`https://res.cloudinary.com/dqmyvpfu0/image/upload/v1643828411/banners/webinar_banner_u0l3py.webp`}
            alt="articles banner"
            width="100%"
            height="60%"
            loading="lazy"
          />
        </figure>
        <section style={{ padding: "5rem 0" }}>
          <div className="container">
            <h1 className="heading text-white text-center">Webinars</h1>
          </div>

          <div className="container-fluid d-relative">
            <div className="bar-block-design">
              <div className="article-grid-container">
                {webinars.map((article, index) => {
                  return (
                    <div className="card article-card" key={index}>
                      <img
                        src={article.image}
                        width="100%"
                        height="100%"
                        className="card-img-top"
                        alt={article.title}
                        loading="lazy"
                      />
                      <div className="card-body article-card-text-block text-center">
                        <h5 className="card-title">{article.title}</h5>
                        <a
                          className="btn dark-outline-btn"
                          target="_blank"
                          rel="noreferrer"
                          href={`${article.videoURL}`}
                        >
                          View Webinar
                        </a>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}

import { useState, Suspense, lazy } from "react";
import MetaSEO from "../components/MetaSEO";
import { Modal, Row, Col } from "react-bootstrap";
// import ImageCollage from "../components/ImageCollage";
const ImageCollage = lazy(() => import("../components/ImageCollage"));

export default function App() {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
  };

  console.log("cscsd", process.env.PUBLIC_URL);

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton className="header-modal" />
        <Modal.Body className="text-center w-100">
          {/* <div className="d-flex gap-3 justify-content-center align-items-center">
            
            <img
              className="img-fluid"
              src="assets/img/innovation-in-education-award.webp"
              alt="popup-review"
              width={`80%`}
              height={"50%"}
              loading="lazy"
            />
          </div> */}
          <Row>
            <Col sm={12} md={6}>
              <img
                className="img-fluid"
                src="https://res.cloudinary.com/dqmyvpfu0/image/upload/v1699110509/books/FakeNews_cfez6y.webp"
                alt="popup-review"
                width={`75%`}
                height={"100%"}
                loading="lazy"
              />

              <a
                href="https://amzn.eu/d/6crKXBV"
                target="_blank"
                rel="noreferrer"
                className="btn btn-lg dark-outline-btn"
              >
                Buy Now
              </a>
            </Col>

            <Col sm={12} md={6}>
              <img
                className="img-fluid"
                src="https://res.cloudinary.com/dqmyvpfu0/image/upload/v1643825930/books/C_m3lgtm.webp"
                alt="popup-review"
                width={`75%`}
                height={"100%"}
                loading="lazy"
              />

              <a
                href="https://amzn.eu/d/fG5sG39"
                target="_blank"
                rel="noreferrer"
                className="btn btn-lg dark-outline-btn"
              >
                Buy Now
              </a>
            </Col>
          </Row>
        </Modal.Body>

        {/* <Modal.Footer className="justify-content-center popup-bar-text">
          <a
            href="https://www.amazon.in/dp/9354793533/ref=cm_sw_r_wa_api_glt_i_5S21MQ3S554HJ9HW1THT"
            target="_blank"
            rel="noreferrer"
            className="btn btn-lg yellow-outline-btn"
          >
            Buy Book Now
          </a>
          <a
            href="https://open.spotify.com/show/0YnF9ztr2dRRe6hu7SmfgZ?si=Jp6hyPPvRLa60JXPyZj6cw"
            target="_blank"
            rel="noreferrer"
            className="btn btn-lg yellow-outline-btn"
          >
            Listen Podcast
          </a>
        </Modal.Footer> */}
      </Modal>

      <MetaSEO title={`Home - Dr. Gaurav Sood`} />

      <section className="m-0 p-0">
        <div className="header-section">
          <video
            width="100%"
            height="100%"
            autoPlay={true}
            loop={true}
            muted={window.innerWidth < 768 ? true : false}
            playsInline={true}
            data-object-fit="cover"
            preload="metadata"
            className="background-video"
          >
            <source
              src={`${process.env.PUBLIC_URL}/assets/video/banner.m4v`}
              type="video/m4v"
              data-wf-ignore="true"
            />
            <source
              src={`${process.env.PUBLIC_URL}/assets/video/banner.m4v`}
              type="video/ogg"
            />
            <source
              src={`${process.env.PUBLIC_URL}/assets/video/banner.m4v`}
              type="video/mp4"
            />
          </video>
        </div>

        {/* FIXME: Without Sound: https://res.cloudinary.com/kunaaaaalllll/video/upload/v1642929991/Starbucks__The_First_50_Years_1_zsm4qe.mp4 */}
        {/* FIXME: With Sound: https://res.cloudinary.com/dqmyvpfu0/video/upload/v1644164479/Video/Starbucks__The_First_50_Years_1_1_riqheb.mp4 */}

        <section className="video-text-position">
          <div className="bar">
            <div className="content-box">
              <h1 className="bar-text">
                "Strong brands are more vulnerable to its doppelganger image"
              </h1>
              <div className="text-center">
                <h4>Now Available on Amazon</h4>
                <a
                  href="https://www.amazon.in/dp/9354793533/ref=cm_sw_r_wa_api_glt_i_5S21MQ3S554HJ9HW1THT"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-lg yellow-outline-btn"
                >
                  India
                </a>

                <a
                  href="https://www.amazon.com/Troll-Proof-Branding-Age-Doppelgangers-dp-9354793533/dp/9354793533/ref=mt_other?_encoding=UTF8&me=&qid=1645176639"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-lg yellow-outline-btn"
                >
                  USA
                </a>

                <a
                  href="https://www.amazon.co.uk/Troll-Proof-Branding-Age-Doppelgangers/dp/9354793533/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1645177557&sr=8-1"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-lg yellow-outline-btn"
                >
                  UK
                </a>
              </div>
            </div>
          </div>
        </section>
      </section>

      <div id="about"></div>
      {/* about section */}

      <section style={{ padding: "5rem 0" }}>
        <div className="container">
          <h1 className="heading text-white text-center">About the Author</h1>
          <article className="text-white">
            <div className="row">
              <div className="col-md-4 my-2 text-center">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/img/profile-img.webp`}
                  className="img-fuild rounded shadow "
                  width={"80%"}
                  height={"100%"}
                  alt="Gaurav Sood"
                  loading="lazy"
                />
              </div>
              <div className="col-md-8 my-2">
                <p className="about-text-alignment">
                  Professor (Dr) Gaurav Sood, Founder & Chief Evangelist, HYPE
                  Brand Consultancy is Amazon Best Seller Author of "Troll Proof
                  Branding in the Age of Doppelgänger”, "Innovator in Education
                  Awardee 2023", a Brand Evangelist, Researcher, Educator,
                  Author, Speaker, Columnist and Brand & Study Abroad
                  Consultant.
                </p>
                <p className="about-text-alignment">
                  He has seen the evolving face of global media and advertising
                  and contributed immensely towards rejuvenating and lending a
                  fresh perspective towards brand communication to major
                  corporate players in the realms of engineering, IT,
                  telecommunications, FMCG, services, consumer durables and
                  media marketing in both in India and the United States. He is
                  Professor of Marketing and his research work has focused on
                  the nouveau field of 'doppelganger brand imagery', and his
                  research papers and articles have been published in leading
                  academic journals and business and marketing magazines. His
                  work has also achieved remarkable acceptance by academicians
                  and practitioners across multiple fields. He has also authored
                  four books:
                </p>

                <ul>
                  <li className="font-main">
                    Branding in the digital Age - 2024
                  </li>
                  <li className="font-main">
                    Digital Marketing and Advertising (Text Book) - 2024
                  </li>
                  <li className="font-main">
                    FAKE NEWS - Spot it. Stop it. (Penguin Random House) - 2023
                  </li>
                  <li className="font-main">
                    Troll Proof Branding in the Age of Doppelgangers (Sage
                    Publications) - 2022
                  </li>
                  <li className="font-main">
                    Global Business Strategies for Sustainability - 2021,
                    co-edited by Gaurav Sood and J. K. Sharma
                  </li>
                  <li className="font-main">
                    Global Business Strategies for Sustainability - 2019,
                    co-edited by Gaurav Sood and J. K. Sharma
                  </li>
                  <li className="font-main">
                    Impact of Doppelganger Brand Image on Indian Premiere League
                    (IPL) - 2018
                  </li>
                </ul>

                <p className="about-text-alignment">
                  He is an alumnus of Amity University (PhD); Emerson College,
                  Boston; Institute of Management Technology, Ghaziabad; and
                  Panjab University, Chandigarh. He keeps his own website{" "}
                  <a href="www.gauravsood.in">www.gauravsood.in</a>.
                  <br />
                  He is a notable guest blogger at BW Businessworld. Dr Sood is
                  also on the editorial board of reputed international journals
                  such as International Journal on Document Analysis and
                  Recognition (IJDAR) and International Journal of Public Sector
                  Performance Management.
                </p>

                <p className="about-text-alignment">
                  To get up to date with articles visit:{" "}
                  <a href="https://www.businessworld.in/author/Guest-Author/Gaurav-Sood-82796">
                    Business World
                  </a>{" "}
                  and{" "}
                  <a href="http://bwmarketingworld.businessworld.in/author/Guest-Author/Dr-Gaurav-Sood-82796/">
                    Marketing World
                  </a>
                </p>
              </div>
            </div>
          </article>
        </div>
      </section>

      <section style={{ padding: "5rem 0" }}>
        <div className="container">
          <h1 className="heading text-white text-center">Success of My Book</h1>

          <h4 className="text-white text-center text-bold">
            It was a pleasure to present my book to India's top journalists
          </h4>
          {/* -Rajdeep Sardesai, Vikram Chandra, Rahul Shankar and graceful Sonia Singh :) */}

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr))",
              gap: 4,
              marginTop: "2rem",
              // placeItems: "end center"
            }}
          >
            <div className="text-center">
              <img
                src={`${process.env.PUBLIC_URL}/assets/img/promotion-x.jpeg`}
                width="86%"
                height="100%"
                className="img-fluid"
                alt="promotion 01"
                title="promotion 01"
                loading="lazy"
              />
              <p className="text-white text-center mt-2 fs-6">
                Sweta Singh, Aajtak
              </p>
            </div>
            <div className="text-center">
              <img
                src="https://res.cloudinary.com/dqmyvpfu0/image/upload/v1715628207/New%20folder/IMG_3070_z8z4qi.jpg"
                width="90%"
                height="100%"
                className="img-fluid"
                alt="promotion 01"
                title="promotion 01"
                loading="lazy"
              />
              <p className="text-white text-center mt-2 fs-6">
                Sweta Singh, Aajtak
              </p>
            </div>
            <div className="text-center">
              <img
                src="https://res.cloudinary.com/dqmyvpfu0/image/upload/v1715628251/New%20folder/IMG_3075_qsexsc.jpg"
                width="90%"
                height="100%"
                className="img-fluid"
                alt="Sonia Singh"
                title="Sonia Singh"
                loading="lazy"
              />
              <p className="text-white text-center mt-2 fs-6">
                Ghanshyam Tiwari, Spokesperson Samajwadi Party
              </p>
            </div>
            <div className="text-center">
              <img
                src="https://res.cloudinary.com/dqmyvpfu0/image/upload/v1715790765/New%20folder/IMG_3078_yb4qpw.jpg"
                width="90%"
                height="100%"
                className="img-fluid"
                alt="promotion 03"
                title="promotion 03"
                loading="lazy"
              />
              <p className="text-white text-center mt-2 fs-6">
                Syed Zafar Islam, spokesperson, BJP
              </p>
            </div>
            <div className="text-center">
              <img
                src="https://res.cloudinary.com/dqmyvpfu0/image/upload/v1715628193/New%20folder/IMG_3083_m9m2bm.jpg"
                width="90%"
                height="100%"
                className="img-fluid"
                alt="promotion 04"
                title="promotion 04"
                loading="lazy"
              />
              <p className="text-white text-center mt-2 fs-6">
                Rahul Kanwal, News Director at India Today TV
              </p>
            </div>

            <div className="text-center">
              <img
                src="https://res.cloudinary.com/dqmyvpfu0/image/upload/v1715628196/New%20folder/IMG_3067_ijmntl.jpg"
                width="90%"
                height="100%"
                className="img-fluid"
                alt="promotion 04"
                title="promotion 04"
                loading="lazy"
              />
              <p className="text-white text-center mt-2 fs-6">
                Sayeed Ansari, Executive Editor, Aajtak
              </p>
            </div>

            <div className="text-center">
              <img
                src="https://res.cloudinary.com/dqmyvpfu0/image/upload/c_crop,ar_3:4/v1715628195/New%20folder/IMG_2843_nm8vly.png"
                width="90%"
                height="100%"
                className="img-fluid"
                alt="promotion 04"
                title="promotion 04"
                loading="lazy"
              />
              <p className="text-white text-center mt-2 fs-6">
                Rajdeep Sardesai, Consulting editor and an anchor of India Today
                TV
              </p>
            </div>
          </div>
        </div>
      </section>

      <Suspense fallback={<p>loading...</p>}>
        <div className="container">
          <ImageCollage />
        </div>
      </Suspense>
    </>
  );
}

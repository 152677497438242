import React from "react";
import { articles } from "../model/articles";

import MetaSEO from "./MetaSEO";

const ShowSingleArticle = (props) => {
  return (
    <section className="bg-white">
      {articles
        .filter(
          (articleContent) => articleContent.slug === props.match.params.slug
        )
        .map((articleContent) => {
          return (
            <>
              <MetaSEO title={`${articleContent.title} - Dr. Gaurav Sood`} />

              <figure className="Fold" key={articleContent.id}>
                <img
                  src={articleContent.image}
                  alt={articleContent.title}
                  className="img-fluid article-img"
                  loading="lazy"
                  width={'100%'}
                  height={'100%'}
                />
              </figure>
              <div
                className="container"
                style={{ padding: "5rem 0", width: "93%" }}
              >
                <h1 className="heading text-center">{articleContent.title}</h1>
                <div
                  className="article-text"
                  dangerouslySetInnerHTML={{
                    __html: articleContent.content,
                  }}
                ></div>
              </div>
            </>
          );
        })}
    </section>
  );
};

export default ShowSingleArticle;

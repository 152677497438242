import { cases } from "../model/cases";
import MetaSEO from "../components/MetaSEO";

export default function Cases() {
  return (
    <>
      <MetaSEO title={`Cases - Dr. Gaurav Sood`} />

      <section className="py-5">
        <h1 className="heading text-white text-center">Cases</h1>

        {cases.map((caseItem, index) => {
          return (
            <figure style={{ position: "relative" }} key={caseItem.id}>
              <img
                src={caseItem.banner}
                alt="expertise_img_V3"
                className="img-fluid "
                width="100%"
                height="50%"
                loading="lazy"
              />

              <section className="img-ribbon-text-position">
                <div className="case-black-box">
                  <h3>{caseItem.name}</h3>
                  <div>
                    <a
                      className="btn yellow-outline-btn"
                      href={`/case/${caseItem.slug}`}
                    >
                      View the case
                    </a>
                  </div>
                </div>
              </section>
            </figure>
          );
        })}
      </section>
    </>
  );
}

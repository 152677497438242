import React from "react";
import { Linkedin, Facebook, Twitter, Instagram } from "react-bootstrap-icons";

const Header = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-black sticky-top">
        <div className="container">
          <a className="navbar-brand fw-bold" href="/">
            Gaurav Sood
          </a>
          <a className="btn white-outline-btn cus-head-bun " href="/contact-us">
            Let's Talk
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item my-auto py-2 px-2">
                <a
                  href="https://www.linkedin.com/in/dr-gaurav-sood-73399514/"
                  target="_blank"
                  className="link-light"
                  rel="noreferrer"
                >
                  <Linkedin size={20} className="me-3" />
                </a>
                <a
                  href="https://www.facebook.com/gaurav.sood.395"
                  target="_blank"
                  className="link-light"
                  rel="noreferrer"
                >
                  <Facebook size={20} className="me-3" />
                </a>
                <a
                  href="https://twitter.com/imgauravsood"
                  target="_blank"
                  className="link-light"
                  rel="noreferrer"
                >
                  <Twitter size={20} className="me-3" />
                </a>
                <a
                  href="https://www.instagram.com/dr.gaurav_sood/"
                  target="_blank"
                  className="link-light"
                  rel="noreferrer"
                >
                  <Instagram size={24} className="me-5" />
                </a>
              </li>
              <li className="nav-item my-auto px-1" style={{ fontSize: 18 }}>
                <a className="nav-link active fw-bold" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item my-auto px-1" style={{ fontSize: 18 }}>
                <a className="nav-link active fw-bold" href="/hype">
                  Hype Brand
                </a>
              </li>
              <li className="nav-item my-auto px-1" style={{ fontSize: 18 }}>
                <a className="nav-link active fw-bold" href="/books">
                  Books
                </a>
              </li>
              <li className="nav-item my-auto px-1" style={{ fontSize: 18 }}>
                <a className="nav-link active fw-bold" href="/media">
                  Media
                </a>
              </li>
              <li className="nav-item my-auto px-1" style={{ fontSize: 18 }}>
                <a className="nav-link active fw-bold" href="/articles">
                  Articles
                </a>
              </li>
              <li className="nav-item my-auto px-1" style={{ fontSize: 18 }}>
                <a className="nav-link active fw-bold" href="/cases">
                  Cases
                </a>
              </li>
              <li className="nav-item my-auto px-1" style={{ fontSize: 18 }}>
                <a className="nav-link active fw-bold" href="/study-abroad">
                  Study Abroad
                </a>
              </li>
              <li className="nav-item my-auto px-1" style={{ fontSize: 18 }}>
                <a className="nav-link active fw-bold" href="/webinars">
                  Webinars
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;

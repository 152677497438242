export const MEDIA = [
  {
    title: "FAKE NEWS - Spot it. Stop it",
    url: null,
    video: `${process.env.PUBLIC_URL}/assets/video/book-promotion-video.mp4`,
    image: null
  },
  {
    title: "FAKE NEWS - Spot it. Stop it",
    url: null,
    image:
      "https://res.cloudinary.com/dqmyvpfu0/image/upload/v1707567438/Media/dbehhvhy7qbnetcwiwul.png",
  },
  {
    title: "Troll Proof Branding in the age of Doppelganger",
    url: null,
    image:
      "https://res.cloudinary.com/dqmyvpfu0/image/upload/v1707567439/Media/vx4ntdglzwmjgneoi4nn.png",
  },
  {
    title: "Troll Proof Branding in the age of Doppelganger",
    url: "https://bwmarketingworld.businessworld.in/article/Dr-Gaurav-Sood-s-Book-Is-A-Savior-For-Brands/18-02-2022-420923/",
    image:
      "https://res.cloudinary.com/dqmyvpfu0/image/upload/v1703923155/Media/u1vnxmtmh9mcqxbqrm8i.png",
  },
  {
    title: "Troll Proof Branding in the age of Doppelganger",
    url: "https://www.youtube.com/watch?v=QHrwr2xvEGA",
    image:
      "https://res.cloudinary.com/dqmyvpfu0/image/upload/v1707548510/Media/yti7teuxwnubiaz5a4hb.png",
  },
  {
    title: "Troll Proof Branding in the age of Doppelganger",
    url: null,
    image:
      "https://res.cloudinary.com/dqmyvpfu0/image/upload/v1703923155/Media/gawcgohjytsyx32bc8fe.png",
  },
  {
    title: "Troll Proof Branding in the age of Doppelganger",
    url: "https://www.indianretailer.com/article/technology-e-commerce/digital-trends/strong-brands-should-never-let-their-guards-down",
    image:
      "https://res.cloudinary.com/dqmyvpfu0/image/upload/v1643826652/banners/banner_for_praises_tmyv44.webp",
  },
  {
    title: "FAKE NEWS - Spot it. Stop it",
    url: "https://soundcloud.com/kushal-mehra-99891819/fake-news-spot-it-stop-it",
    image:
      "https://res.cloudinary.com/dqmyvpfu0/image/upload/v1707565426/Media/lkovae7iao1bahpjmakl.png",
  },
  {
    title: "FAKE NEWS - Spot it. Stop it",
    url: "https://theprint.in/softcover/new-book-on-fake-news-unveils-dark-secrets-of-misinformation-offers-insightful-analysis/1877059/",
    image:
      "https://res.cloudinary.com/dqmyvpfu0/image/upload/v1707565426/Media/l3uja4lrbzzs7ofijifu.png",
  },
  {
    title: "FAKE NEWS - Spot it. Stop it",
    url: "https://www.youtube.com/watch?v=4nd0Xkkm0WY",
    image:
      "https://res.cloudinary.com/dqmyvpfu0/image/upload/v1703923155/Media/we0e1ukqeeilhxrrwzhj.jpg",
  },
  {
    title: "FAKE NEWS - Spot it. Stop it",

    url: "https://theprint.in/pageturner/excerpt/starbucks-kalyan-jewellers-pepsi-how-big-brands-fell-victim-to-culture-jamming/1881254/",
    image:
      "https://static.theprint.in/wp-content/uploads/2023/12/starbucks-5460799_640-e1702290417734.jpg",
  },
  {
    title: "FAKE NEWS - Spot it. Stop it",

    url: "https://youtu.be/_01k3KzINno?si=FwBP8OibOOmOqER0",
    image:
      "https://i.ytimg.com/vi/_01k3KzINno/maxresdefault.jpg",
  },
];

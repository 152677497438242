import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAYS1mclsPW2MRO7OAgGNGm_zJNzibF2vY",
  authDomain: "portfolio-be15a.firebaseapp.com",
  projectId: "portfolio-be15a",
  storageBucket: "portfolio-be15a.appspot.com",
  messagingSenderId: "502290170191",
  appId: "1:502290170191:web:774d90da6bca72f2f7453a",
  measurementId: "G-Y1PJ5VTH0N",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };

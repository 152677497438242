import React, { useState, useEffect } from "react";

import {
  Linkedin,
  Facebook,
  Twitter,
  ArrowUp,
  Instagram,
} from "react-bootstrap-icons";

import { db } from "../firebase";
import { collection, addDoc } from "firebase/firestore";

const currentYear = new Date().getFullYear();

const Footer = () => {
  const [getUpdatesData, setUpdatesData] = useState({
    name: "",
    email: "",
  });

  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  });

  const handleDataChange = async (event) => {
    setUpdatesData({
      ...getUpdatesData,
      [event.target.name]: event.target.value,
    });
  };

  const handleNewsSubmit = async (event) => {
    event.preventDefault();
    if (getUpdatesData !== null) {
      await addDoc(collection(db, "updates"), getUpdatesData)
        .then((res) => {
          event.target.reset();
          alert("Thanks for subscribing");
        })
        .catch((err) => console.log(err));
    }
    setUpdatesData({
      name: null,
      email: null,
    });
    console.log(getUpdatesData);
  };

  return (
    <>
      <div className="scroll-to-top">
        {isVisible && (
          <button
            className="btn yellow-outline-btn"
            id="btn"
            onClick={scrollToTop}
          >
            <ArrowUp size={20} />
          </button>
        )}
      </div>
      <section style={{ paddingTop: "4rem" }} id="footer">
        <div className="container yellow-border">
          <div className="row mt-4">
            <div className="col-md-6 my-2">
              <h2 className="footer-header">Subscribe to NEWSLETTER</h2>

              <form method="POST" onSubmit={handleNewsSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="form-control bg-transparent text-white w-50"
                    placeholder="Name"
                    onChange={handleDataChange}
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control bg-transparent text-white w-50"
                    placeholder="Email"
                    onChange={handleDataChange}
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="submit"
                    value="Submit"
                    name="submit"
                    className="btn yellow-outline-btn mx-0"
                  />
                </div>
              </form>
            </div>

            <div className="col-md-6 my-2">
              <h2 className="footer-header">FOLLOW GAURAV SOOD</h2>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                <a
                  href="https://www.linkedin.com/in/dr-gaurav-sood-73399514/"
                  target="_blank"
                  className="link-light"
                  rel="noreferrer"
                >
                  <Linkedin size={24} className="me-5" />
                </a>
                <a
                  href="https://www.facebook.com/gaurav.sood.395"
                  target="_blank"
                  className="link-light"
                  rel="noreferrer"
                >
                  <Facebook size={24} className="me-5" />
                </a>
                <a
                  href="https://twitter.com/imgauravsood"
                  target="_blank"
                  className="link-light"
                  rel="noreferrer"
                >
                  <Twitter size={24} className="me-5" />
                </a>
                <a
                  href="https://www.instagram.com/dr.gaurav_sood/"
                  target="_blank"
                  className="link-light"
                  rel="noreferrer"
                >
                  <Instagram size={24} className="me-5" />
                </a>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "2.5rem",
                }}
              >
                <a
                  href="/privacy-policy"
                  className="link-light me-3"
                  style={{
                    fontSize: 18,
                    textDecoration: "none",
                  }}
                >
                  Privacy
                </a>
                {/* <a href="/legal" className="link-light">
                  Legal
                </a> */}
              </div>
            </div>
          </div>

          <div className="text-center">
            <p className="text-white">
              Copyright &copy; {currentYear}{" "}
              <a href="www.gauravsood.in">www.gauravsood.in</a> | All Rights
              Reserved
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;

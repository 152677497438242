import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <>
      <section className="page-not-found">
      <div className="container text-center">
        <h1 className="mb-3 heading text-main-color">404 Page Not Found</h1>
        <p className='text-light'>
          This page does not exist, <Link to="/">Click here</Link> to go back
        </p>
      </div>
    </section>
    </>
  )
}

export default NotFound
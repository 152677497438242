import React from "react";
import { cases } from "../model/cases";

import MetaSEO from "./MetaSEO";

const ShowSingleCase = (props) => {
  return (
    <section className="bg-white py-5">
      <div className="container">
        {cases
          .filter((x) => x.slug === props.match.params.slug)
          .map((item) => {
            return (
              <>
                <MetaSEO title={`${item.name} - Dr. Gaurav Sood`} />

                <h2 className="heading text-center">{item.name}</h2>
                <div
                  className="article-text"
                  dangerouslySetInnerHTML={{
                    __html: item.description,
                  }}></div>
              </>
            );
          })}
      </div>
    </section>
  );
};

export default ShowSingleCase;

import MetaSEO from "../components/MetaSEO";

export default function Privacy() {
  return (
    <>
      <MetaSEO title={`Privacy Policy - Dr. Gaurav Sood`} />

      <section className="py-5">
        <h1 className="heading text-white text-center">Privacy Policy</h1>
      </section>

      <section className="container py-3 text-white">
        <p>WELCOME TO gauravsood.in!</p>
        <i className="d-block mb-3">last updated on 06th November, 2023 </i>
        <p>
          It is of the utmost importance for us at gauravsood.in to ensure that
          this, and every subsequent visit you make to our websites, is with
          complete peace of mind. You have every right to trust and to expect
          confidentiality, and it is our duty to respect this right.
        </p>
        <p>
          Your privacy is our priority. As such, we believe you should have an
          unrestricted view into how we collect data about the people who visit
          our site - yourself included - and what we do with that data.
        </p>
        <p>
          So, this is the part where we show our true colors. In black, white
          and yellow, here is your…..
        </p>
        <p>Privacy Policy from gauravsood.in.</p>
        <p>
          We are committed to protecting your privacy. We will only use the
          information that we collect about you lawfully (in accordance with the
          Data Protection Act 1998 and the EU Privacy and Communications
          Directive). This includes information submitted voluntarily by you
          through a form or gathered automatically as you visit this website.
        </p>
        <p>
          You should not submit any sensitive or private information through
          this website except through acknowledged secure areas. The owners of
          this website accept no responsibility for data submitted through
          insecure areas of this website.
        </p>
        <p>
          By submitting your contact details to us, we may contact you or send
          you marketing materials. We will give you the chance to refuse any
          marketing email from us in the future.
        </p>
        <p>
          This website uses cookies which are stored on your browser. You can
          usually modify your browser settings to prevent this happening.
          However, by disabling cookies, or certain types of cookie, you may
          hinder your user experience on this and other websites, or prevent
          them from working entirely.
        </p>
        <p>
          Some cookies used on this website are essential in order to enable you
          to move around the website and use its features, such as accessing
          secure areas of the website. Without these cookies, services you have
          asked for, like shopping baskets or e-billing, cannot be provided.
        </p>
        <p>
          Some cookies used on this website collect information about how
          visitors use a website, for instance which pages visitors go to most
          often, and if they get error messages from web pages.
        </p>
        <p>
          These cookies don't collect information that identifies a visitor. All
          information these cookies collect is aggregated and therefore
          anonymous. It is only used to improve how a website works.
        </p>
        <p>
          Some cookies used on this website allow the website to remember
          choices you make (such as your user name, language or the region you
          are in) and provide enhanced, more personal features.
        </p>
        <p>
          These cookies can also be used to remember changes you have made to
          text size, fonts and other parts of web pages that you can customise.
          They may also be used to provide services you have asked for such as
          watching a video or commenting on a blog. The information these
          cookies collect may be anonymised and they cannot track your browsing
          activity on other websites.
        </p>
        <p>
          Cookies may also be used to improve the user experience and to enable
          some of the functionality provided by this website.
        </p>
        <p>
          Please note that some cookies may be placed by third party service
          providers who perform some of these functions (or other services) for
          us. Cookies are used on this website for the following third party
          services: Google Analytics, LinkedIn Follow button, Facebook Like
          Button, Twitter Follow button, Google +1 button, AddThis social
          bookmarking tool, YouTube videos. Some of these services may be used
          to track your behaviour on other websites and we have no control over
          this.
        </p>
        <p>
          By continuing to use this website without adjusting your browser's
          cookie settings, you agree that we can place these cookies on your
          device.
        </p>
        <p>
          As is true with most websites, our server will automatically log data
          regarding each visit such as your IP address, browser type,
          referring/exit pages, and operating system. We may use this
          information to monitor server errors, server administration or to
          monitor visitor behaviour. It is not possible for this to be disabled
          on a per-user basis so you must leave this website (and the internet
          entirely) if you do not agree to this happening.
        </p>
        <p>
          This privacy policy applies to this website only and does not apply to
          any websites that this website may link to.
        </p>
        <p>
          If you have any questions/comments about privacy, you should contact
          us.
        </p>
        <p>
          This privacy policy is copyrighted under a GNU/GPL V3 licence which
          means that you may copy it to your site and modify it to your needs,
          in return for a link back to this website.
        </p>
        <p>
          https://www.openglobal.co.uk/articles/2-uncategorised/120-privacy-policy.html
        </p>
      </section>
    </>
  );
}

import React from "react";
import MetaSEO from "../components/MetaSEO";

export default function StudyAbroad() {
  return (
    <>
      <MetaSEO title={`Study Abroad - Dr. Gaurav Sood`} />

      <section className="container text-white">
        <article className="py-5">
          <figure className="mb-4 container-md text-center">
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/bg-study-abroad.jpg`}
              alt="Study Abroad logo"
              width={800}
              className="img-fluid"
            />
          </figure>

          <h1 className="fw-bold text-center text-main-color">Study Abroad</h1>
        </article>

        <article className="mb-5">
          <div className="row">
            <div className="col-md-3 my-2 text-center">
              <img
                src={`${process.env.PUBLIC_URL}/assets/img/study-abroad.jpeg`}
                className="img-fuild rounded shadow "
                width={"100%"}
                height={"100%"}
                alt="Gaurav Sood"
                loading="lazy"
              />
            </div>
            <div className="col-md-9 my-2">
              <p>
                Dr. Gaurav Sood, Mentor to SABLE EdTech - a leader in study
                abroad consultancy is a dedicated and experienced professional
                in the field of international education. With over 28 years of
                experience, in which 18 years working at leadership position in
                global organization in USA, Europe and India. He has taught and
                helped countless students realize their dreams of higher
                education & studying abroad. Dr. Sood is passionate about
                guiding students through the complex process of applying to
                universities and preparing for life in a new country.
              </p>
              <p>
                Our experienced Ivy League-educated counsellors are here to
                guide you through the complete study abroad process. We are
                committed to helping you find the best course and college for
                you. It's time to realise your dreams! Whether you need to take
                your IELTS, SAT, GRE or GMAT, we are there for you! And the best
                part is, all our services are available online, available to you
                from anywhere and at very reasonable prices. What are you
                waiting for?
              </p>
              <p>Get in touch with us now!</p>
              <p>
                Email:{" "}
                <a href="mailto:gaurav@sablestudyabroad.com">
                  gaurav@sablestudyabroad.com
                </a>
              </p>
              <p>
                Number: <a href="tel:+919811355662">+91 98113 55662</a>
              </p>
              <a
                href={`${process.env.PUBLIC_URL}/assets/downloads/stable-brochure.pdf`}
                target="_blank"
                rel="noreferrer"
                className="btn btn-md yellow-outline-btn"
              >
                Get the brochure now!
              </a>
            </div>
          </div>
        </article>
      </section>
    </>
  );
}

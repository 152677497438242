import React from "react";
import MetaSEO from "../components/MetaSEO";

export default function Legal() {
  return (
    <>
      <MetaSEO title={`Legal - Dr. Gaurav Sood`} />

      <section className="py-5">
        <h1 className="heading text-white text-center">Legal</h1>
      </section>

      <section className="container py-3 text-white">
        <div class="PrivacyCntnt">
          <h2>No warranties</h2>
          <p>
            This website is provided "as is" without any representations or
            warranties, express or implied. Gaurav Sood makes no representations
            or warranties in relation to this website or the information and
            materials provided on this website.
          </p>
          <p>
            Without prejudice to the generality of the foregoing paragraph,
            Gaurav Sood does not warrant that:
          </p>
          <ul>
            <li>
              This website will be constantly available, or available at all; or
            </li>
            <li>
              The information on this website is complete, true, accurate or
              non-misleading.
            </li>
          </ul>
          <p>
            Nothing on this website constitutes, or is meant to constitute,
            advice of any kind.
          </p>
          <h2>Limitations of liability</h2>
          <p>
            Gaurav Sood will not be liable to you (whether under the law of
            contact, the law of torts or otherwise) in relation to the contents
            of, or use of, or otherwise in connection with, this website:
          </p>
          <ul>
            <li>
              To the extent that the website is provided free-of-charge, for any
              direct loss;
            </li>
            <li>For any indirect, special or consequential loss; or</li>
            <li>
              For any business losses, loss of revenue, income, profits or
              anticipated savings, loss of contracts or business relationships,
              loss of reputation or goodwill, or loss or corruption of
              information or data.
            </li>
          </ul>
          <p>
            These limitations of liability apply even if Gaurav Sood has been
            expressly advised of the potential loss.
          </p>
          <h2>Exceptions</h2>
          <p>
            Nothing in this website disclaimer will exclude or limit any
            warranty implied by law that it would be unlawful to exclude or
            limit; and nothing in this website disclaimer will exclude or limit
            Gaurav Sood's liability in respect of any:
          </p>
          <ul>
            <li>Death or personal injury caused by [NAME'S] negligence;</li>
            <li>
              Fraud or fraudulent misrepresentation on the part of [NAME]; or
            </li>
            <li>
              matter which it would be illegal or unlawful for [NAME] to exclude
              or limit, or to attempt or purport to exclude or limit, its
              liability.
            </li>
          </ul>
          <h2>Reasonableness</h2>
          <p>
            By using this website, you agree that the exclusions and limitations
            of liability set out in this website disclaimer are reasonable.
          </p>
          <p>
            If you do not think they are reasonable, you must not use this
            website.
          </p>
          <h2>Other parties</h2>
          <p>
            You accept that, as a limited liability entity, Gaurav Sood has an
            interest in limiting the personal liability of its officers and
            employees. You agree that you will not bring any claim personally
            against Gaurav Sood's officers or employees in respect of any losses
            you suffer in connection with the website.
          </p>
          <p>
            Without prejudice to the foregoing paragraph, you agree that the
            limitations of warranties and liability set out in this website
            disclaimer will protect Gaurav Sood's officers, employees, agents,
            subsidiaries, successors, assigns and sub-contractors as well as
            Gaurav Sood.
          </p>
          <h2>Unenforceable provisions</h2>
          <p>
            If any provision of this website disclaimer is, or is found to be,
            unenforceable under applicable law, that will not affect the
            enforceability of the other provisions of this website disclaimer.
          </p>
        </div>
      </section>
    </>
  );
}

export const webinars = [
  {
    id: -6,
    videoURL: "https://www.youtube.com/watch?v=GecuaMV5NlM",
    title: `Troll Proof Your brand with Influencer Marketing!`,
    speaker: "Dr. Gaurav Sood",
    image:
      "https://res.cloudinary.com/dqmyvpfu0/image/upload/v1651767236/webinars/maxresdefault_kajbau.jpg",
  },
  {
    id: -5,
    videoURL: "https://www.youtube.com/watch?v=QHrwr2xvEGA&t=693s",
    title: `Tête-à-tête with Dr Gaurav Sood`,
    speaker: "",
    image:
      "https://res.cloudinary.com/dqmyvpfu0/image/upload/v1651767326/webinars/hqdefault_fppfcp.jpg",
  },
  {
    id: -4,
    videoURL: "https://www.youtube.com/watch?v=K6Tfq7KNxd0",
    title: "The new media revolution in India",
    speaker: "Mr. Ritesh Dhawan",
    image:
      "https://res.cloudinary.com/dqmyvpfu0/image/upload/v1647685685/webinars/WhatsApp_Image_2022-03-19_at_3.29.16_PM_nbvcuq.jpg",
  },
  {
    id: -3,
    videoURL: "https://youtu.be/meAQJx4uHIU",
    title: "How to create a strong brand positioning for effective targeting",
    speaker: "Ms. Harpreet Kaur",
    image:
      "https://res.cloudinary.com/dqmyvpfu0/image/upload/v1647342781/webinars/How_to_create_a_strong_brand_positioning_for_effective_targeting_mu1snx.jpg",
  },
  {
    id: -2,
    videoURL: "https://youtu.be/Cp9dzAlX5UY",
    title: "Decision Making Using Digital Marketing Metrics",
    speaker: "Dr. Victoria Ellis",
    image:
      "https://res.cloudinary.com/dqmyvpfu0/image/upload/v1646575748/webinars/Decision_Making_Using_Digital_Marketing_Metrics_zufesk.jpg",
  },
  {
    id: -1,
    videoURL: "https://youtu.be/k0kUBiAlS2o",
    title: "Webinar on How to Grow your Brand",
    speaker: "Tracy Millar",
    image:
      "https://res.cloudinary.com/dqmyvpfu0/image/upload/v1644335778/webinars/how-to-grow-your-brand_kxrjj4.jpg",
  },
  {
    id: 0,
    videoURL: "https://youtu.be/4wn81MYaA1M",
    title:
      "Role of India in the new world order - Padma Bhushan Prof. Jagdish N. Sheth, Emory University, USA",
    speaker: "Prof. Jagdish N. Sheth",

    image:
      "https://res.cloudinary.com/dqmyvpfu0/image/upload/v1644072682/webinars/3_o4la8j.jpg",
  },
  {
    id: 1,
    videoURL: "https://youtu.be/d15tkjPnvFs",
    title: "Analytics to drive Digital Marketing Strategies",
    speaker: "Prof. Dr. Gaurav Sood",

    image:
      "https://res.cloudinary.com/dqmyvpfu0/image/upload/v1643961358/webinars/Analytics_to_drive_digital_marketing_strategies_tcllev.jpg",
  },
  {
    id: 2,
    videoURL: "https://youtu.be/5hl0IkJVanw",
    title: "How to build a Digital Brand?",
    speaker: "Mr.Jaipal Singh",

    image:
      "https://res.cloudinary.com/dqmyvpfu0/image/upload/v1643961360/webinars/How_to_build_a_Digital_Brand_ejghbh.jpg",
  },
  {
    id: 3,
    videoURL: "https://youtu.be/HFzK4xTeFYY",
    title:
      "Harveen Bedi in Conversation with Prof. (Dr.) Gaurav Sood on Changing work places post pandemic",
    speaker: "Dr. Harveen Bedi",

    image:
      "https://res.cloudinary.com/dqmyvpfu0/image/upload/v1643961362/webinars/Harveen_Bedi_in_Conversation_with_Prof._Dr._Gaurav_Sood_on_Changing_work_places_post_pandemic_sw6olm.png",
  },
  {
    id: 4,
    videoURL: "https://youtu.be/APSVqUj281s",
    title: "Career in the Sports Industry",
    speaker: "G Srinivvasan",

    image:
      "https://res.cloudinary.com/dqmyvpfu0/image/upload/v1643961359/webinars/Career_in_the_Sports_Industry_kaglsq.jpg",
  },
  {
    id: 5,
    videoURL: "https://youtu.be/YIiTl8JHvj4",
    title: "Career in Management Consulting (Big Four)",
    speaker: "Mr. Ranveer Lal",

    image:
      "https://res.cloudinary.com/dqmyvpfu0/image/upload/v1643961358/webinars/Career_in_Management_Consulting_Big_Four_a0dxzc.jpg",
  },
  {
    id: 6,
    videoURL:
      "https://drive.google.com/file/d/1JE1Y6o8zkc7vr2jL-XDJDcrYoBOtSSip/view",
    title:
      "Metal Branding - Branding for Start-up Ventures and Creative Enterprises",
    speaker: "Mr. Douglas Quintal",

    image:
      "https://res.cloudinary.com/dqmyvpfu0/image/upload/v1643961360/webinars/Metal_Branding_-_Branding_for_Start-up_Ventures_and_Creative_Enterprises_kczp6r.jpg",
  },
  {
    id: 7,
    videoURL: "https://youtu.be/e_4V7MnodRg",
    title: "How to use Google Analytics",
    speaker: "Mr. Amitabh Verma",
    image:
      "https://res.cloudinary.com/dqmyvpfu0/image/upload/v1643961361/webinars/How_to_use_Google_Analytics_mv7wxr.jpg",
  },
  {
    id: 8,
    videoURL: "https://youtu.be/tZgTJ5pWfR8",
    title:
      "Interview with Sandeep Nagpal, Director Leadership International & CEO Ladders HR",
    speaker: "Mr. Sandeep Nagpal",
    image:
      "https://res.cloudinary.com/dqmyvpfu0/image/upload/v1643961363/webinars/Interview_with_Sandeep_Nagpal_Director_Leadership_International_CEO_Ladders_HR_xpsd2l.png",
  },
  {
    id: 9,
    videoURL: "https://youtu.be/NIKou3oGemY",
    title:
      "CEO Talks - Exclusive Interaction with Dhananjay singh, NHRDN Director General",
    speaker: "Mr. Dhananjay Singh",
    image:
      "https://res.cloudinary.com/dqmyvpfu0/image/upload/v1643961364/webinars/CEO_Talks_-_Exclusive_Interaction_with_Dhananjay_singh_NHRDN_Director_General_tfr9rb.png",
  },
  {
    id: 10,
    videoURL: "https://youtu.be/Z067Y9ApJVA",
    title:
      "CEO Talk with Mr Subrat Kumar, CEO & Co-Founder of Cinque Education Pvt Ltd.",
    speaker: "Mr Subrat Kumar",
    image:
      "https://res.cloudinary.com/dqmyvpfu0/image/upload/v1643961363/webinars/CEO_Talk_with_Mr_Subrat_Kumar_CEO_Co-Founder_of_Cinque_Education_Pvt_Ltd._ko4v78.png",
  },
  {
    id: 11,
    videoURL: "https://youtu.be/rXa-5RcQPAU",
    title:
      "Watch Dr. Gaurav Sood in conversation with Mr. Amitabh Verma, CEO, and Founder of AMP Digital",
    speaker: "Mr. Amitabh Verma",
    image:
      "https://res.cloudinary.com/dqmyvpfu0/image/upload/v1643961365/webinars/Watch_Dr._Gaurav_Sood_in_conversation_with_Mr._Amitabh_Verma_CEO_and_Founder_of_AMP_Digital_ritk4x.png",
  },
];

import { Modal, Button } from "react-bootstrap";
import { useState, Suspense, lazy } from "react";
import { books } from "../model/books";
import MetaSEO from "../components/MetaSEO";
const BookReview = lazy(() => import("../components/BookReview"));

export default function Books() {
  const [show, setShow] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseAbout = () => setShowAbout(false);
  const handleShowAbout = () => setShowAbout(true);

  return (
    <>
      <MetaSEO title={`Books - Dr. Gaurav Sood`} />

      <figure className="Fold">
        {/* <img
          src={`https://res.cloudinary.com/dqmyvpfu0/image/upload/v1643825930/banners/book_banner_yg1pe4.webp`}
          alt="articles banner"
          width="100%"
          height="100%"
          loading="lazy"
        /> */}
        <img
          src={`https://res.cloudinary.com/dqmyvpfu0/image/upload/v1699116637/banners/fakeNews_jrrv4z.webp`}
          alt="Fake News Book Cover"
          width="100%"
          height="100%"
          loading="lazy"
        />
      </figure>

      <section style={{ padding: "5rem 0" }}>
        <div className="container">
          <h1 className="heading text-white text-center">
            BOOKS BY Dr. GAURAV SOOD
          </h1>
        </div>

        <div className="container-fluid d-relative">
          <div className="bar-block-design">
            <Suspense fallback={<p>loading...</p>}>
              {books.map((book, index) => {
                return (
                  <div className="BookDataDiv" key={index}>
                    <div className="BookDataLeft">
                      <h4>WANT TO LEARN MORE ABOUT…</h4>
                      <ul>
                        {book.keyPoints.map((point, index) => {
                          return <li key={index}>{point}</li>;
                        })}
                      </ul>
                    </div>

                    <div className="BookDataRight">
                      <div className="row" style={{ alignItems: "center" }}>
                        <div className="col-md-3 my-3">
                          <img
                            src={book.bookCover}
                            className="BookDataRightBook img-fluid"
                            alt={book.bookTitle}
                            loading="lazy"
                            width={"100%"}
                            height={"100%"}
                          />
                        </div>
                        <div className="col-md-9 my-3">
                          <div className="BookDataRightCntnt">
                            <h4>{book.bookTitle}</h4>
                            <p style={{ textAlign: "justify" }}>
                              {book.mainContent}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Suspense>
          </div>
        </div>
      </section>

      <section className="container">
        <h3 className="heading text-white text-center">
          Advance praises for the book <br /> "TROLL PROOF BRANDING IN THE AGE
          OF DOPPELGANGER"
        </h3>

        <div className="text-center">
          <Button
            className="btn btn-lg yellow-outline-btn"
            onClick={handleShow}
          >
            Buy on Amazon
          </Button>
          <Button
            onClick={handleShowAbout}
            className="btn btn-lg yellow-outline-btn"
          >
            About the book
          </Button>
        </div>
        <Modal show={show} onHide={handleClose} size="md" centered>
          <Modal.Footer className="justify-content-center">
            <a
              href="https://www.amazon.in/dp/9354793533/ref=cm_sw_r_wa_api_glt_i_5S21MQ3S554HJ9HW1THT"
              target="_blank"
              rel="noreferrer"
              className="btn btn-lg dark-outline-btn"
            >
              India
            </a>

            <a
              href="https://www.amazon.com/Troll-Proof-Branding-Age-Doppelgangers-dp-9354793533/dp/9354793533/ref=mt_other?_encoding=UTF8&me=&qid=1645176639"
              target="_blank"
              rel="noreferrer"
              className="btn btn-lg dark-outline-btn"
            >
              USA
            </a>
            <a
              href="https://www.amazon.co.uk/Troll-Proof-Branding-Age-Doppelgangers/dp/9354793533/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1645177557&sr=8-1"
              target="_blank"
              rel="noreferrer"
              className="btn btn-lg dark-outline-btn"
            >
              UK
            </a>
          </Modal.Footer>
        </Modal>
        <Modal show={showAbout} onHide={handleCloseAbout} size="md" centered>
          <Modal.Footer className="justify-content-center">
            <a
              href="http://bwmarketingworld.businessworld.in/article/Dr-Gaurav-Sood-s-Book-Is-A-Savior-For-Brands/18-02-2022-420923/"
              target="_blank"
              rel="noreferrer"
              className="btn btn-lg dark-outline-btn"
            >
              Article
            </a>

            <a
              href="https://m.youtube.com/watch?v=XLM5Ey-VYgk"
              target="_blank"
              rel="noreferrer"
              className="btn btn-lg dark-outline-btn"
            >
              Video
            </a>
          </Modal.Footer>
        </Modal>

        <figure className="mx-auto text-center">
          <img
            src={`https://www.gauravsood.in/assets/img/meta_img.jpg`}
            alt="articles banner"
            width={"75%"}
            height={"100%"}
            loading="lazy"
          />
        </figure>
        <figure className="mx-auto text-center">
          <video
            src={`https://res.cloudinary.com/dqmyvpfu0/video/upload/v1654954495/Book_Review_nmm9fa.mp4`}
            alt="book-review"
            width="25%"
            controls="true"
            className="mx-3"
          />
          <video
            src={`https://res.cloudinary.com/dqmyvpfu0/video/upload/v1654954546/Boo_review_spum2i.mp4`}
            alt="book-review"
            width="25%"
            controls="true"
            className="mx-3"
          />
        </figure>
        <Suspense fallback={<p>loading...</p>}>
          <BookReview />
        </Suspense>
      </section>
    </>
  );
}

// FIXME: Best seller badge
/*
<span className="BooksNewLogo">
  <img
    style={{ height: 120, width: 135, paddingLeft: 20 }}
    className="book-logo"
    src="https://www.martinlindstrom.com/wp-content/uploads/2018/10/Group-23-Copy@2x.png"
    alt=""
  />
</span>
*/

import { articles } from "../model/articles";
import MetaSEO from "../components/MetaSEO";

export default function Articles() {
  return (
    <>
      <MetaSEO title={`Articles - Dr. Gaurav Sood`} />
      <figure className="Fold">
        <img
          src={`https://res.cloudinary.com/dqmyvpfu0/image/upload/v1643826665/banners/article_banner_ct59dd.webp`}
          alt="articles banner"
          width="100%"
          height="100%"
          loading="lazy"
        />
      </figure>

      <section style={{ padding: "5rem 0" }}>
        <div className="container">
          <h1 className="heading text-white text-center">Articles</h1>
          <h2 className="text-white text-center mb-4">
            Published in leading business magazines
          </h2>
        </div>

        <div className="container-fluid d-relative">
          <div className="bar-block-design">
            <div className="article-grid-container">
              {articles.map((article, index) => {
                return (
                  <div className="card article-card" key={index}>
                    <img
                      src={article.image}
                      width="100%"
                      height="100%"
                      className="card-img-top"
                      alt={article.title}
                      loading="lazy"
                    />
                    <div className="card-body article-card-text-block text-center">
                      <p className="card-title">
                        <q>{article.title}</q>
                      </p>
                      <p className="m-0">--{article.publications}--</p>
                      <p className="m-0">{article.date}</p>
                      <a
                        className="btn dark-outline-btn"
                        href={`/article/${article.slug}`}
                        style={{ fontSize: 16 }}
                      >
                        {" "}
                        Learn more
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
